h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
p {
  padding: 5px  !important;
}
.section {
  width: 100%;
}
.icon {
  font-size: 3rem;
  color: green;
}
.section-center {
  margin: 0 auto;
  margin-top: 3rem;
  width: 100%;
  height: 75vh;
  position: relative;
  display: flex;
  overflow: hidden;
}
article {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
  background: #ecf5f0;
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}
.slider-img-person {
  flex: 1;
  width: 100%;
  height: 100%;
}
.slider-img-person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articles-texts {
    text-align: start;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
}

article h4 {
  margin-bottom: 0.15rem;
  font-weight: bold;
  font-size: xx-large;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: green;
  font-size: x-large;
  margin: 0;
}
.text {
    font-style: italic;
    margin: 0;
    font-size: x-large;
}
.texxt {
  margin-top: 1rem;
}
.prev,
.next {
  position: absolute;
  top: 350px;
  transform: translateY(-50%);
  background: green;
  width: 2rem;
  height: 2rem;
  color: #fff;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.prev:hover,
.next:hover {
  background: white;
  color: green;
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 767px) {
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  top: 200px;
  }
}
@media (max-width: 400px) {
  .section-center {
    height: 120vh !important;
  }
}

@media (max-width: 767px) {
  article {
   flex-direction: column;
  }
  .section-center {
    height: 100vh;
  }
  .slider-img-person {
    flex: 1;
    width: 100%;
    height: 50vh;
  }
  .slider-img-person img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  article h4 {
    font-size: x-large;
    margin-top: 1rem;
  }
  .title {
    font-size: large;
    margin-bottom: 0.2rem;
  }
  .articles-texts {
    margin: 0 ;
    text-align: center;
    justify-content: inherit;
}
.texxt {
  margin-top: 0.2rem;
}
}
