@import './variables';

.projectss {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.about-txt {
    padding: 6%;
}
.projects-landing-blog {
    margin: 0 5rem;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px){
        margin: 0 2rem;
        }
}
.first-section {
    height: 50vh;
    left: 0;
    top: 0;
    margin-bottom: 4rem ;
    border-radius: 10px;
    width: 100%;
    background: url(../../headerImages/projectLanding.jpg);
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .outer {
    position: absolute;
    border-radius: 10px;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
  }
  .first-details {
    left: 50%;
    top: 50%;
    width: 80%;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
  
    h1 {
      text-transform: capitalize;
      font-family: "EB Garamond", serif;
    }
    p {
      margin: 1rem 0;
    }
  }
  .firstblog-flex {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      @media (max-width: 767px){
        flex-direction: column;
        gap: 20px;
        }
  }
  .project-header-title {
      font-size: 5rem;
  }
  .projects-volunteer {
      margin-bottom: 2rem;
  }
  .volunteer-flex {
      display: flex;
      justify-content: space-between;
      margin: 4rem ;

      @media (max-width: 767px){
        flex-direction: column;
        gap: 20px;
        margin: 2rem ;
        }
  }
  .volunteer-flex1 {
      width: 45%;
      align-items: flex-start;
      text-align: start;

      p {
          margin: 1rem 0 2rem 0;

          @media (max-width: 767px){
            margin: .5rem 0 ;
          }
      }

      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
      }
      @media (max-width: 767px){
          width: 100%;
          text-align: center;
          align-items: center;
        }
  }
  .vol-btn {
    background: rgb(60, 24, 91);
    color: white;
    padding: 3% 7%;
    border-radius: 10px;
    width: fit-content;
    margin-left: 2rem;
    margin-top: 1rem;

    a {
        color: white;
    }

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        align-items: center;
      }
  }
  .vol-btnnn {
    background: green;
    color: white;
    padding: 3% 7%;
    border-radius: 10px;
    width: fit-content;
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    a {
        color: white;
    }

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        align-items: center;
        margin-left: 0rem;
        margin-top: 0rem;
      }
  }

//   Featured Projects
//   Featured Projects

.featured-landing-blog {
    margin: 6% 3%;
    h2 {
        text-align: start;
        margin-bottom: 0;
        @media (max-width: 400px){
            font-size: large;
          }
    }
}
.feut-flex {
    display: flex;
    gap: 5%;
    align-items: center;
    color: rgb(60, 24, 91);
}
.featured-first-section {
    height: 60vh;
    left: 0;
    top: 0;
    margin: 0rem 4rem 4rem 4rem ;
    border-radius: 10px;
    background: url(../../headerImages/climate.jpg);
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media (max-width: 767px){
        margin: 0rem 2rem 4rem 2rem;
        height: 70vh;
      }
      @media (max-width: 500px){
        margin: 0rem 1rem 4rem 1rem;
        height: 75vh;
      }
}
.feautured-outer {
    position: absolute;
    border-radius: 10px;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(145, 103, 7, 0.9);

    a {
        color: white;
        border-bottom: 2px solid white;
        padding-bottom: 5px;
    }
  }

//   View Projects
//   View Projects

.view-fs {
    min-height: 100vh;
    background: url(https://images.pexels.com/photos/1134076/pexels-photo-1134076.jpeg?cs=srgb&dl=pexels-dazzle-jam-1134076.jpg&fm=jpg);
    color: white;
    background-position: cover;
  background-size: cover;
  position: relative;

    h2 {
        text-align: start;
    }
    h3 {
        text-transform: uppercase;
        font-weight: bolder;
        font-size: xx-large;
        margin: 1rem 0;
        padding: 0% 5%;

        @media (max-width: 767px){
            font-size: x-large;
          }
    }
}
.view-fs-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(3, 50, 3, 0.6);
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.view-fs-header {
    margin-bottom: 5%;
    h1 {
        color: #E1DB46;
        font-weight: bolder;
        text-transform: uppercase;
    }
}
.view-fs-btn-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}
.view-fs-btn {
    border: 2px solid #E1DB46;
    border-radius: 10px;
    background: #E1DB46;
    color: white;
    width: 13rem;
    height: 4rem;
    padding: 15px;
    text-align: center;
    font-weight: bolder;
    text-decoration: none;

    @media (max-width: 600px){
        width: 8rem;
        height: 3rem;
        padding: 7px;
      }
}
.view-sc {
    min-height: 100vh;
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px){
        padding: 3% 5%;
      }
}
.view-container {
    width: 100%;
}
.links-sc {
    text-align: start;
    font-size: 2rem;
    
    a {
        color: black;
        font-size: 1.5rem;
    }
    .active {
        font-weight: bolder !important;
        font-size: larger;
        background: red;
    }
}

// View Projects Summary
// View Projects Summary

.brief-box {
    border: 1px solid black;
    text-align: start;
    padding: 2% 5%;
    margin-top: 2rem;

    h3{
        font-weight: bolder;
        margin-bottom: 1rem;
    }
}
.cross-flex {
    display: flex;
    margin-top: 4%;
    h2 {
        color: black;
        font-weight: bolder;
    }
}
.cross-icon {
    color: green;
    font-size: larger;
    margin-right: 3.5%;
}
.cross-container {
    text-align: start;
    margin-left: 5%;
    
    h5 {
        font-weight: bolder;
        margin-top: 1rem;
    }
    h4{
        font-weight: bolder;
        font-size: large;
        text-transform: none;
        margin: 2rem 0 1rem 0;
    }
    p {
        margin: 1rem 0;
    }
    span {
        color: green;
    }
    @media (max-width: 767px){
        margin-left: 0%;
      }
}
.ipcc {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 1% 6%;
    margin-top: 2%;

    p {
        text-align: start;
        font-weight: bolder;
    }
    span {
        display: flex;
        justify-content: flex-end;
    color: green;
    }
    @media (max-width: 767px){
        padding: 1% 2%;
      }
}
.project-trans {
    background: lightgray;
    padding: 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px){
        flex-direction: column;
      }
}
.h20 {
    width: 40%;
    height: 40%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.h2-n2{
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    
}
.vp-img-sect {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.vp-img-hgt {
    width: 50vh;
    height: 60vh;
    border: 7px solid green;
    margin: 1rem 0;
}
.vp-img-flex {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
}
.stats-vs {
    background: rgb(233, 225, 240);
    min-height: 100vh;
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stats-vs-cont {
    width: 100%;
}
.stats-grid-cont {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-row-gap: 1px;
    grid-column-gap: 1px;
}
.stat-grid {
    background: green;
    color: white;
    padding: 10% 0%;

    p {
        padding: 0;
        margin: 0;
    }
    h3 {
        font-size: large;
        font-weight: bolder;
        @media (max-width: 767px){
            font-size: medium;
            font-weight: normal;
          }
    }
    @media (max-width: 767px){
        margin-bottom: 2%;
      }
}
.volunteer-far {
    display: flex;
    margin-top: 5%;
    justify-content: space-between;

    @media (max-width: 767px){
        display: none;
      }
}
.sct-1 {
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    h5 {
        font-weight: bolder;
        margin-bottom: 2%;
    }
}
.sct-2 {
    background: green;
    color: white;
    padding: 3%;
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h5 {
        font-weight: bolder;
        margin-bottom: 2%;
    }
}
.sct-2-cont {
    width: 87%;
    @media (max-width: 767px){
        width: 100%;
      }
}
.bdf {
    display: flex;
}
.sct-2-mobile {
    display: none;
    @media (max-width: 767px){
        display: block;
      }
}
.first-section-mobile {
    height: 70vh;
    left: 0;
    top: 0;
    margin-bottom: 4rem ;
    width: 100%;
    background: url(../../headerImages/planting.jpg);
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .outer-mobile {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(70, 170, 115, 0.7);
  }
  .first-details-mobile {
    left: 50%;
    top: 50%;
    width: 90%;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
  
    h1 {
      text-transform: capitalize;
      font-family: "EB Garamond", serif;
    }
    p {
      margin: 1rem 0;
    }
  }
//   Team Slider



// View Project Photo Album
// View Project Photo Album

.photo-album {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-row-gap: 2rem;
    grid-column-gap: 1rem;
    padding: 2%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: 767px){
        grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 1rem;
      }
}
.volunteer-faq {
    display: flex;
    margin-top: 5%;
    justify-content: space-between;

    @media (max-width: 767px){
        flex-direction: column;
        gap: 20px;
      }
}
.volunteer-sct {
    align-items: flex-start;
    text-align: start;
    flex: 1;
}
.volunteer-sct-flex {
    display: flex;
    text-align: start;

    h4 {
        font-weight: bolder;
    }
    span {
        color: goldenrod;
        margin-right: 4%;
    }
    p {
        padding: 0;
        margin: 0;
    }
}
.faq-sct {
    flex: 1;
    border: 1px solid;
    border-radius: 5px;
    background: #e2f0e4;
    padding: 2%;
}
.acc-body {
    // background: #f0e2f0;
    text-align: start;
}
.acc-title {
    text-align: start;
    padding: 4%;
    font-weight: bold;
}
.acc-item {
    text-align: start;
    border-top: 1px solid grey;
    padding: 4% 2%;
}
.acc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h4 {
        font-size: large;
        font-weight: bold;
    }
    span {
        font-size: larger;
        font-weight: bold;
    }
}
.acc-content {
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
}
.acc-content-show {
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
    max-height: 9999px;
    height: auto;
    margin: 3% 0;
    color: grey;
}